@import url('https://fonts.maateen.me/bensen/font.css');
body {
  font-family: 'BenSen', Arial, sans-serif !important;
}
.print-before-hide{
  display: none;
}
.print-area{
  padding: 0;
  margin: 0;
}
.variant_input{
  width: 300px;
  padding: 10px 15px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
@media print {
  body * {
    visibility: hidden;
  }
  .print-area, .print-area * {
    visibility: visible;
  }
  .print-area .no-print{
    display: none;
  }
  .print-area {
    position: absolute;
    top: 0;
    left: 0;
  }
  .print-area .print-before-hide{
    display: block;
    text-align: center;
    padding: 0px 5px;
    margin: 0;
  }
}
